import React from 'react';
import Header from './Header';
import Carousel from './Carousel';
import HomePedagogico from './HomePedagogico';
import VideoBackground from './VideoBackground';
import Services from './Services';

const Home = () => {
  return (
    <div>
      <Header />
      <Carousel />
      <HomePedagogico />
      <VideoBackground />
      <Services />
    </div>
  );
};

export default Home;