import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebaseConfig';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Admin = () => {
  const navigate = useNavigate();
  const [collectionName, setCollectionName] = useState('');
  const [resultados, setResultados] = useState([]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login'); // Redirige al usuario a la página de inicio de sesión
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };

  const handleCollectionChange = async (event) => {
    const selectedCollection = event.target.value;
    setCollectionName(selectedCollection);

    if (selectedCollection) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/${selectedCollection}`);
        setResultados(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setResultados([]);
    }
  };

  return (
    <div className="container">
      <div className="mb-4">
        <h4 className="text-center">Estas en la administración</h4>
        <button onClick={handleLogout} className="btn btn-danger">Cerrar sesión</button>
      </div>

      <div>
        <label className="form-label">Seleccione el menú a editar</label>
        <select className="form-select" value={collectionName} onChange={handleCollectionChange}>
          <option value="" disabled>Seleccione una colección</option>
          <option value="carousel">Carrusel</option>
          <option value="resenahistorica">Reseña historica</option>
          <option value="horizonte">Horizonte institucional</option>
          <option value="directivos">Directivos</option>
          <option value="administrativos">Administrativos</option>
          <option value="docentes">Docentes</option>
          <option value="rendicioncuentas">Rendicion Cuentas</option>
          <option value="enfoquepedagogico">Enfoque Pedagógico</option>
          <option value="modelopedagogico">Modelo Pedagógico</option>
          <option value="manualconvivencia">Manual de Convivencia</option>
          <option value="tramiteretiros">Tramites de retiros</option>
          <option value="protocolobioseguridad">Protocolo de bioseguridad</option>
          <option value="convocatorias">Convocatorias</option>
        </select>
      </div>

      <div className="mt-3">
        {/* crear carousel */}
        {collectionName === 'carousel' && (
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate('/carouselCreate')}
          >
            Crear Nuevo Carousel
          </button>
        )}
        {/* crear directivo */}
        {collectionName === 'directivos' && (
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate('/directivosCreate')}
          >
            Crear Nuevo Directivo
          </button>
        )}
        {/* crear administrativo */}
        {collectionName === 'administrativos' && (
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate('/administrativosCreate')}
          >
            Crear Nuevo Administrativo
          </button>
        )}
        {/* crear docentes */}
        {collectionName === 'docentes' && (
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate('/docentesCreate')}
          >
            Crear Nuevo Docente
          </button>
        )}
        {/* crear convocatorias */}
        {collectionName === 'convocatorias' && (
          <button
            className="btn btn-primary mb-3"
            onClick={() => navigate('/convocatoriasCreate')}
          >
            Crear Nueva Convocatoria
          </button>
        )}
      </div>

      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Título</th>
            <th>Texto uno</th>
            <th>Texto dos</th>
            <th>Texto tres</th>
            <th>Texto cuatro</th>
            <th>Imágen</th>
            <th>Modificar</th>
          </tr>
        </thead>
        <tbody>
          {resultados.length > 0 ? (
            resultados.map((item) => (
              <tr key={item._id}>
                <td>{item.title || item.itemtitle}</td>
                <td>{item.stringone || item.itemone}</td>
                <td>{item.stringtwo || item.itemtwo}</td>
                <td>{item.stringthree || item.itemthree}</td>
                <td>{item.stringfour || item.itemfour}</td>
                <td>
                  <img src={`/media/${item.image || item.itemimage}`} alt="" style={{ width: '100px' }} />
                </td>
                <td>
                  <Link to={`/${collectionName}Edit/${item._id}`} className="link-primary">
                    Modificar
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
