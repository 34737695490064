import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importa axios para hacer solicitudes HTTP

const Horizonte = () => {
  const [horizonteData, setHorizonteData] = useState([]);

  useEffect(() => {
    // Función para obtener datos desde el servidor
    const fetchHorizonteData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/horizonte`); 
        setHorizonteData(response.data);
      } catch (error) {
        console.error('Error fetching reseña historica data:', error);
      }
    };

    fetchHorizonteData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {/* Renderiza el contenido de la reseña historica */}
      {horizonteData.map((item) => (
        <div key={item._id} className="resena-item">
          <div 
            className='backgroundresena' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className='container'>
            <div className="caption">
              <h2 className='text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className='row'>
                <div className="col-sm-6 p-4 mt-3 bg-primary bg-gradient bg-opacity-50 fadediv">
                    <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3>
                    <p className='text-justify'>{item.stringone}</p>
                </div>
                <div className="col-sm-6 p-4 mt-3 bg-white bg-opacity-50">
                    <h3 className='text-primary text-center fw-bold'>{item.titletwo}</h3>
                    <p className='text-justify'>{item.stringtwo}</p>
                </div>
            </div>
            <div className="p-4 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titlethree}</h3>
              <p className='text-justify'>{item.stringthree}</p>
            </div>
            <div className='row'>
                <div className="col-sm-6 p-4 mt-3 bg-white bg-opacity-50 ">
                    <h3 className='text-primary text-center fw-bold'>{item.titlefour}</h3>
                    {/* Renderiza item.stringfive como una lista si es un array */}
                    {Array.isArray(item.stringfour) ? (
                      <ul>
                        {item.stringfour.map((listItem, index) => (
                          <li key={index}>{listItem}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className='text-justify'>{item.stringfour}</p>
                    )}
                </div>
                <div className="col-sm-6 p-4 mt-3 bg-primary bg-gradient bg-opacity-50">
                    <h3 className='text-primary text-center fw-bold'>{item.titlefive}</h3>
                    {/* Renderiza item.stringfive como una lista si es un array */}
                    {Array.isArray(item.stringfive) ? (
                      <ul>
                        {item.stringfive.map((listItem, index) => (
                          <li key={index}>{listItem}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className='text-justify'>{item.stringfive}</p>
                    )}
                </div>
            </div>
            <div className="p-4 mt-3 bg-white bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titlesix}</h3>
              <p className='text-justify'>{item.stringsix}</p>
            </div>
            <div className="p-4 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleseven}</h3>
              <p className='text-justify'>{item.stringseven}</p>
            </div>
            <div className="p-4 mt-3 bg-white bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleeight}</h3>
              <p className='text-justify'>{item.stringeight}</p>
            </div>
            <div className="p-4 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titlenine}</h3>
              <p className='text-justify'>{item.stringnine}</p>
            </div>
            <div className="p-4 mt-3 bg-white bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleten}</h3>
              <p className='text-justify'>{item.stringten}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Horizonte;
