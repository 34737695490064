import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image';

const RendicionCuentasEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    titleone: '',
    stringone: '',
    pdfoneName: '',
    titletwo: '',
    stringtwo: '',
    pdftwoName: '',
    titlethree: '',
    stringthree: '',
    pdfthreeName: '',
    imageName: '',
  });

  const [pdfFiles, setPdfFiles] = useState({
    pdfone: null,
    pdftwo: null,
    pdfthree: null,
    image: null,
  });

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/rendicioncuentas/${id}`);
        setItemData(response.data);
      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    };

    fetchItemData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    const formData = new FormData();
    Object.keys(pdfFiles).forEach((key) => {
      if (pdfFiles[key]) {
        formData.append(key, pdfFiles[key]);
      }
    });

    try {
      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const uploadedFiles = uploadResponse.data.files;
      const updatedItemData = {
        ...itemData,
        pdfone: uploadedFiles.pdfone || itemData.pdfone,
        pdfoneName: uploadedFiles.pdfone ? pdfFiles.pdfone.name : itemData.pdfoneName,
        pdftwo: uploadedFiles.pdftwo || itemData.pdftwo,
        pdftwoName: uploadedFiles.pdftwo ? pdfFiles.pdftwo.name : itemData.pdftwoName,
        pdfthree: uploadedFiles.pdfthree || itemData.pdfthree,
        pdfthreeName: uploadedFiles.pdfthree ? pdfFiles.pdfthree.name : itemData.pdfthreeName,
        image: uploadedFiles.image || itemData.image,
        imageName: uploadedFiles.image ? pdfFiles.image.name : itemData.imageName,
      };

      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/rendicioncuentas/${id}`, updatedItemData);
      navigate('/admin');
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  const handleDrop = (acceptedFiles, fieldName) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const maxSize = 6 * 1024 * 1024; // 6MB
  
      if (selectedFile.size > maxSize) {
        alert('El archivo excede el límite: 6MB');
        return;
      }
  
      if (fieldName === 'image') {
        // Validar la resolución de la imagen
        loadImage(
          selectedFile,
          (img) => {
            const { naturalWidth: width, naturalHeight: height } = img;
            const requiredWidth = 1920;
            const requiredHeight = 650;
  
            if (width !== requiredWidth || height !== requiredHeight) {
              alert(`La imagen debe tener una resolución de ${requiredWidth}x${requiredHeight}px`);
              return;
            }
  
            setPdfFiles((prevFiles) => ({
              ...prevFiles,
              [fieldName]: selectedFile,
            }));
  
            setItemData((prevData) => ({
              ...prevData,
              [`${fieldName}Name`]: selectedFile.name,
            }));
          },
          { meta: true }
        );
      } else {
        setPdfFiles((prevFiles) => ({
          ...prevFiles,
          [fieldName]: selectedFile,
        }));
  
        setItemData((prevData) => ({
          ...prevData,
          [`${fieldName}Name`]: selectedFile.name,
        }));
      }
    }
  };
  

  const { getRootProps: getRootPropsPdfOne, getInputProps: getInputPropsPdfOne } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdfone'),
  });
  const { getRootProps: getRootPropsPdfTwo, getInputProps: getInputPropsPdfTwo } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdftwo'),
  });
  const { getRootProps: getRootPropsPdfThree, getInputProps: getInputPropsPdfThree } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdfthree'),
  });
  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    onDrop: (files) => handleDrop(files, 'image'),
  });

  return (
    <div className="container">
      <h2 className="text-center">Editar Rendicion de Cuentas</h2>
      <form>
        <div className="mb-3">
          <label className="form-label fw-bold">Título</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className='mt-5'>
            <div className="mb-3">
            <label className="form-label fw-bold">Título uno</label>
            <input
                type="text"
                className="form-control"
                name="titleone"
                value={itemData.titleone}
                onChange={handleChange}
            />
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">Texto uno</label>
            <textarea
                className="form-control"
                rows="3"
                name="stringone"
                value={itemData.stringone}
                onChange={handleChange}
            ></textarea>
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">PDF uno</label>
            <div {...getRootPropsPdfOne()} className="dropzone">
                <input {...getInputPropsPdfOne()} />
                {itemData.pdfoneName ? (
                <p>{itemData.pdfoneName}</p>
                ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
                )}
            </div>
            </div>
        </div>
        <div className='mt-5'>
            <div className="mb-3">
            <label className="form-label fw-bold">Título dos</label>
            <input
                type="text"
                className="form-control"
                name="titletwo"
                value={itemData.titletwo}
                onChange={handleChange}
            />
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">Texto dos</label>
            <textarea
                className="form-control"
                rows="3"
                name="stringtwo"
                value={itemData.stringtwo}
                onChange={handleChange}
            ></textarea>
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">PDF dos</label>
            <div {...getRootPropsPdfTwo()} className="dropzone">
                <input {...getInputPropsPdfTwo()} />
                {itemData.pdftwoName ? (
                <p>{itemData.pdftwoName}</p>
                ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
                )}
            </div>
            </div>
        </div>
        <div className='mt-5'>    
            <div className="mb-3">
            <label className="form-label fw-bold">Título tres</label>
            <input
                type="text"
                className="form-control"
                name="titlethree"
                value={itemData.titlethree}
                onChange={handleChange}
            />
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">Texto tres</label>
            <textarea
                className="form-control"
                rows="3"
                name="stringthree"
                value={itemData.stringthree}
                onChange={handleChange}
            ></textarea>
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">PDF tres</label>
            <div {...getRootPropsPdfThree()} className="dropzone">
                <input {...getInputPropsPdfThree()} />
                {itemData.pdfthreeName ? (
                <p>{itemData.pdfthreeName}</p>
                ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
                )}
            </div>
            </div>
        </div>
{/*         <div className="mb-3">
          <label className="form-label fw-bold">Imágen</label>
          <input
            type="text"
            className="form-control"
            name="image"
            value={itemData.image}
            onChange={handleChange}
          />
        </div> */}
        <div className="mb-3">
          <label className="form-label fw-bold">imagen</label>
          <div {...getRootPropsImage()} className="dropzone">
              <input {...getInputPropsImage()} />
              {itemData.imageName ? (
              <p>{itemData.imageName}</p>
              ) : (
              <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
          </div>
        </div>
        
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Guardar cambios
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default RendicionCuentasEdit;
