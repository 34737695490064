import React from 'react';

const Copyright = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='text-white p-3 text-center copyright'>
           <p>® Actualización: Institución Educativa La Esperanza - {currentYear}</p>
        </div>
    );
};

export default Copyright;
