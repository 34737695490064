import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword } from '../config/firebaseConfig';
//import apsImage from '../media/aps.png'; // Importa la imagen

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Inicio de sesión exitoso
      console.log('Usuario ha iniciado sesión con éxito');
      navigate('/admin');
    } catch (error) {
      // Manejar errores de inicio de sesión
      //console.error('Error al iniciar sesión:', error.message);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };

  return (
    <div className='row'>
        <div className='col-sm-4'></div>
        <div className='col-sm-4'>
            <div className="mb-3 text-center">
            {/* <img src={apsImage} alt="APS" className="img-fluid img-thumbnail" width="300"/> */}
            <h3 className="text-primary text-center fw-bold" >Ingreso administración página web: I.E. La Esperanza</h3>
            <div className="mb-3 form-check">
                <label className="form-label" for="email">Email</label>
                <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="mb-3 form-check">
                <label className="form-label" for="password">Contraseña</label>
                <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button type="submit" className="btn btn-primary" onClick={handleLogin}>Iniciar sesión</button>
            </div>
        </div>
        <div className='col-sm-4'></div>
    </div>
  );
}

export default Login;