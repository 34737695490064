import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image';

const HorizonteEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    titleone: '',
    stringone: '',
    titletwo: '',
    stringtwo: '',
    titlethree: '',
    stringthree: '',
    titlefour: '',
    stringfour: [],
    titlefive: '',
    stringfive: [],
    titlesix: '',
    stringsix: '',
    titleeight: '',
    stringeight: '',
    titlenine: '',
    stringnine: '',
    titleten: '',
    stringten: '',
    image: '',
    imagemision: '',
    imagevision: '',
  });

  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/horizonte/${id}`);
        setItemData(response.data);
      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    };

    fetchItemData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleArrayChange = (e, arrayName, index) => {
    const { value } = e.target;
    const updatedArray = [...itemData[arrayName]];
    updatedArray[index] = value;
    setItemData((prevData) => ({ ...prevData, [arrayName]: updatedArray }));
  };

  const handleAddStringArray = (arrayName) => {
    setItemData((prevData) => ({ ...prevData, [arrayName]: [...prevData[arrayName], ''] }));
  };

  const handleRemoveStringArray = (arrayName, index) => {
    const updatedArray = itemData[arrayName].filter((_, i) => i !== index);
    setItemData((prevData) => ({ ...prevData, [arrayName]: updatedArray }));
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const uploadedFiles = uploadResponse.data.files;
      const updatedItemData = {
        ...itemData,
        image: uploadedFiles.image || itemData.image,
        imageName: uploadedFiles.image ? imageFile.name : itemData.imageName,
      };

      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/horizonte/${id}`, updatedItemData);
      navigate('/admin');
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const maxSize = 6 * 1024 * 1024; // 6MB
  
      if (selectedFile.size > maxSize) {
        alert('El archivo excede el límite: 6MB');
        return;
      }
  
      // Validar la resolución de la imagen
      loadImage(
        selectedFile,
        (img) => {
          const { naturalWidth: width, naturalHeight: height } = img;
          const requiredWidth = 1600;
          const requiredHeight = 1066;
  
          if (width !== requiredWidth || height !== requiredHeight) {
            alert(`La imagen debe tener una resolución de ${requiredWidth}x${requiredHeight}px`);
            return;
          }
  
          setImageFile(selectedFile);
  
          setItemData((prevData) => ({
            ...prevData,
            imageName: selectedFile.name,
          }));
        },
        { meta: true }
      );
    }
  };
  
  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    onDrop: (files) => handleDrop(files),
  });


  return (
    <div className="container">
      <h2 className="text-center">Editar Horizonte institucional</h2>
      <form>
        <div className="mb-3">
          <label className="form-label fw-bold">Título</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título uno</label>
            <input
              type="text"
              className="form-control"
              name="titleone"
              value={itemData.titleone}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto uno</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringone"
              value={itemData.stringone}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título dos</label>
            <input
              type="text"
              className="form-control"
              name="titletwo"
              value={itemData.titletwo}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto dos</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringtwo"
              value={itemData.stringtwo}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título tres</label>
            <input
              type="text"
              className="form-control"
              name="titlethree"
              value={itemData.titlethree}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto tres</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringthree"
              value={itemData.stringthree}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título cuatro</label>
            <input
              type="text"
              className="form-control"
              name="titlefour"
              value={itemData.titlefour}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto cuatro (agregar campo por cada item de la lista)</label>
            {Array.isArray(itemData.stringfour) && itemData.stringfour.map((str, index) => (
              <div key={index} className="d-flex mb-2">
                <textarea
                  className="form-control"
                  rows="3"
                  value={str}
                  onChange={(e) => handleArrayChange(e, 'stringfour', index)}
                ></textarea>
                <button type="button" className="btn btn-danger ms-2" onClick={() => handleRemoveStringArray('stringfour', index)}>
                  Eliminar
                </button>
              </div>
            ))}
            <button type="button" className="btn btn-primary" onClick={() => handleAddStringArray('stringfour')}>
              Añadir Texto
            </button>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título cinco</label>
            <input
              type="text"
              className="form-control"
              name="titlefive"
              value={itemData.titlefive}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto cinco (agregar campo por cada item de la lista)</label>
            {Array.isArray(itemData.stringfive) && itemData.stringfive.map((str, index) => (
              <div key={index} className="d-flex mb-2">
                <textarea
                  className="form-control"
                  rows="3"
                  value={str}
                  onChange={(e) => handleArrayChange(e, 'stringfive', index)}
                ></textarea>
                <button type="button" className="btn btn-danger ms-2" onClick={() => handleRemoveStringArray('stringfive', index)}>
                  Eliminar
                </button>
              </div>
            ))}
            <button type="button" className="btn btn-primary" onClick={() => handleAddStringArray('stringfive')}>
              Añadir Texto
            </button>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título seis</label>
            <input
              type="text"
              className="form-control"
              name="titlesix"
              value={itemData.titlesix}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto seis</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringsix"
              value={itemData.stringsix}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título siete</label>
            <input
              type="text"
              className="form-control"
              name="titleseven"
              value={itemData.titleseven}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto siete</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringseven"
              value={itemData.stringseven}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título ocho</label>
            <input
              type="text"
              className="form-control"
              name="titleeight"
              value={itemData.titleeight}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto ocho</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringeight"
              value={itemData.stringeight}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título nueve</label>
            <input
              type="text"
              className="form-control"
              name="titlenine"
              value={itemData.titlenine}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto nueve</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringnine"
              value={itemData.stringnine}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className='mt-5'>
          <div className="mb-3">
            <label className="form-label fw-bold">Título diez</label>
            <input
              type="text"
              className="form-control"
              name="titleten"
              value={itemData.titleten}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto diez</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringten"
              value={itemData.stringten}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label fw-bold">imagen</label>
          <div {...getRootPropsImage()} className="dropzone">
            <input {...getInputPropsImage()} />
            {itemData.imageName ? (
              <p>{itemData.imageName}</p>
            ) : (
              <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
            )}
          </div>
        </div>
        
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Guardar cambios
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default HorizonteEdit;
