import React from 'react';

const Radio = () => {
  return (
    <div id="r_Radio">
      <div className='row text-center p-5 mt-5'>
        <h1 className='text-primary fw-bold'>La Esperanza</h1>
        <div className='col-12 col-sm-6 service-item'>
          <h3 className='text-primary fw-bold'>Noticias en Facebook</h3>
          <iframe 
            title='facebook'
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FIELAESPERANZA.FLORENCIA&tabs=timeline&width=310&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="310"
            height="400"
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
        <div className='col-12 col-sm-6 service-item'>
          <h3 className='text-primary fw-bold'>Radio La Esperanza</h3>
          <iframe
            src="https://zeno.fm/player/radio-la-esperanza"
            width="300"
            height="400"
            frameBorder="0"
            scrolling="no"
            title="Radio La Esperanza"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Radio;
