import React from 'react';

const VideoBackground = () => {
  const backgroundImage = '/media/ieesperanza.jpg'; // Ruta a tu imagen

  return (
    <div
      id="r_VideoBackground"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className='row'>
        <div className='text-center col-12 col-sm-6 p-5'>
          <h3 className='text-white fw-bold pt-3'>Proyecto ComunicArte</h3>
          <p className='text-white fw-bold pt-3'>I.E. La Esperanza Florencia La Gloria Experiencia significativa de la I.E. La Esperanza para la participación en el Foro Territorial Florencia Caquetá. Desarrollar una estrategia pedagógica mediada por las TIC, para el fortalecimiento del tejido comunicativo entre estudiantes, padres de familia, docentes y directivos de la Institución</p>
        </div>
        <div className='col-12 col-sm-6 p-5'>
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/zJuPCGBFIMQ?autoplay=1&mute=1&loop=1&playlist=zJuPCGBFIMQ"
              className="video-frame"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;