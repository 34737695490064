import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EnfoquePedagogico = () => {
  const [enfoquepedagogicoData, setEnfoquePedagogicoData] = useState([]);

  useEffect(() => {
    // Función para obtener datos desde el servidor
    const fetchRendicionCuentasData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/enfoquepedagogico`); // Cambia la ruta según tu configuración de servidor
        setEnfoquePedagogicoData(response.data);
      } catch (error) {
        console.error('Error fetching Enfoque Pedagogico data:', error);
      }
    };

    fetchRendicionCuentasData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {enfoquepedagogicoData.map((item) => (
        <div key={item._id} className="">
          <div 
            className='singlebackground' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className="singlebackground-text animated-text ">
            <h2>Para que el aprendizaje sea significativo son necesarias al menos dos condiciones.</h2>
            <p>En primer lugar, el material de aprendizaje debe poseer un significado en sí mismo, es decir, sus diversas partes deben estar relacionadas con cierta lógica; en segundo lugar, que el material resulte potencialmente significativo para el alumno, es decir, que éste posea en su estructura de conocimiento ideas inclusoras con las que pueda relacionarse el material.</p>
          </div>
          <div className='container'>
            <div className="caption">
              <h2 className='p-5 text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className="p-5 mt-3 bg-gradient bg-opacity-50">
              {/* <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3> */}
              <p className='text-justify'>{item.stringone}</p>
            </div>
            <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3>
              <p className='text-justify'>{item.stringtwo}</p>
            </div>
            <div className="p-5 mt-3 bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titlethree}</h3>
              <p className='text-justify'>{item.stringthree}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EnfoquePedagogico;
