import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image';

const AdministrativosCreate = () => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    titleone: '',
    stringone: '',
    stringtwo: '',
    stringthree: '',
    stringfour: '',
    stringfive: '',
    image: '',
  });
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreate = async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('title', itemData.title);
      formData.append('titleone', itemData.titleone);
      formData.append('stringone', itemData.stringone);
      formData.append('stringtwo', itemData.stringtwo);
      formData.append('stringthree', itemData.stringthree);
      formData.append('stringfour', itemData.stringfour);
      formData.append('stringfive', itemData.stringfive);

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/administrativos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/admin');
    } catch (error) {
      console.error('Error creating new item:', error);
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const maxSize = 1 * 1024 * 1024; // 1MB

      if (selectedFile.size > maxSize) {
        alert('El archivo excede el límite: 1MB');
        return;
      }

      // Validar la resolución de la imagen
      loadImage(
        selectedFile,
        (img) => {
          const { naturalWidth: width, naturalHeight: height } = img;
          const requiredWidth = 1040;
          const requiredHeight = 1040;

          if (width !== requiredWidth || height !== requiredHeight) {
            alert(`La imagen debe tener una resolución de ${requiredWidth}x${requiredHeight}px`);
            return;
          }

          setFile(selectedFile);
          setItemData((prevData) => ({ ...prevData, image: selectedFile.name }));
        },
        { meta: true }
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="container">
      <h2 className="text-center">Crear Nuevo Administrativo</h2>
      <form>
        <div className="mb-3">
          <label className="form-label">Nombres</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Apellidos</label>
          <input
            type="text"
            className="form-control"
            name="titleone"
            value={itemData.titleone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Cargo</label>
          <input
            type="text"
            className="form-control"
            name="stringone"
            value={itemData.stringone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Facebook</label>
          <input
            type="text"
            className="form-control"
            name="stringtwo"
            value={itemData.stringtwo}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Teléfono</label>
          <input
            type="text"
            className="form-control"
            name="stringthree"
            value={itemData.stringthree}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">whatsapp</label>
          <input
            type="text"
            className="form-control"
            name="stringfour"
            value={itemData.stringfour}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="text"
            className="form-control"
            name="stringfive"
            value={itemData.stringfive}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Imágen</label>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {file ? (
              <p>{file.name}</p>
            ) : (
              <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
            )}
          </div>
        </div>
        <button type="button" className="btn btn-primary" onClick={handleCreate}>
          Crear
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default AdministrativosCreate;
