import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ModeloPedagogico = () => {
  const [modelopedagogicoData, setModeloPedagogicoData] = useState([]);

  useEffect(() => {
    // Función para obtener datos desde el servidor
    const fetchModeloPedagogicoData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/modelopedagogico`); // Cambia la ruta según tu configuración de servidor
        setModeloPedagogicoData(response.data);
      } catch (error) {
        console.error('Error fetching Modelo Pedagogico data:', error);
      }
    };

    fetchModeloPedagogicoData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {modelopedagogicoData.map((item) => (
        <div key={item._id} className="">
          <div 
            className='singlebackground' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className="singlebackground-text animated-text ">
            <h2>MODELO PEDAGÓGICO</h2>
            <p>El paradigma constructivista considera que las personas no tienen acceso directo a la realidad, sino que la construyen activamente según sus modelos o esquemas mentales.</p>
          </div>
          <div className='container'>
            <div className="caption">
              <h2 className='p-5 text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className="p-5 mt-3 bg-gradient bg-opacity-50">
              {/* <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3> */}
              <p className='text-justify'>{item.stringone}</p>
            </div>
            <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3>
                    {/* Renderiza item.stringfive como una lista si es un array */}
                    {Array.isArray(item.stringtwo) ? (
                      <ul>
                        {item.stringtwo.map((listItem, index) => (
                          <li key={index}>{listItem}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className='text-justify'>{item.stringtwo}</p>
                    )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModeloPedagogico;
