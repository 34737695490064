import React from 'react';
import CarouselComponent from './CarouselComponent'; // Importa el componente CarouselComponent

const Carousel = () => {
  return (
    <div id="r_Carousel">
      {/* Renderiza el componente CarouselComponent */}
      <CarouselComponent />
    </div>
  );
};

export default Carousel;