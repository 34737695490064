import React from 'react';

const Footer = () => {
  const backgroundFooter = ' /media/bg_ieesperanza.png'; 
  return (
    <div id="r_footer" 
    style={{
      backgroundImage: `url(${backgroundFooter})`,
    }}>
      
      <div className='row text-white text-center rounded-top'>
        <div className='col-12 col-sm-4 '>
          <h3 className='fw-bold'>La Esperanza</h3>
            <p>Misión y Visión</p>
            <p>Nuestros Valores</p>
            <p>Proyecto Educativo Institucional </p>
            <p>Objetivos Institucionales</p>
            <p>Filosofía Institucional</p>
            <p>Circulares</p>
            <p>Rendición de cuentas</p>
        </div>
        <div className='col-12 col-sm-4'>
          <h3 className='fw-bold'>Comunidad estudiantil</h3>
            <p>Noticias</p>
            <p>Galería de Fotos</p>
            <p>Política de Privacidad</p>
            <p>Tratamiento de Datos</p>
        </div>
        <div className='col-12 col-sm-4'>
          <h3 className='fw-bold'>Contactenos</h3>
          <p>Dirección: Urbanización La Gloria -Etapa I</p>
          <p>Teléfonos: 3123904245</p>
          <p>E-mail: laesperanza@florencia.edu.co</p>
          <p>Florencia - Caquetá -Colombia.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;