import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBgSg0fScnjJFSm4vgayjKtLlG0JVff2ck",
    authDomain: "ieesperanza.firebaseapp.com",
    projectId: "ieesperanza",
    storageBucket: "ieesperanza.appspot.com",
    messagingSenderId: "929020613881",
    appId: "1:929020613881:web:bd3687260520c45f487e28"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth, signInWithEmailAndPassword };
