import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './utils/global.css';
import Home from './components/Home';
import Navigation from './components/Navigation';
import FloatingButton from './components/FloatingButton';
import Radio from './components/Radio';
import Copyright from './components/Copyright';
import SocialLinks from './components/SocialLinks';
import Footer from './components/Footer';
import Login from './components/Login';
import Admin from './components/Admin';
import { auth } from './config/firebaseConfig';
import CarouselEdit from './components/CarouselEdit';
import ResenaHistoricaEdit from './components/ResenaHistoricaEdit';
import CarouselCreate from './components/CarouselCreate';
import ResenaHistorica from './components/ResenaHistorica';
import Horizonte from './components/Horizonte';
import HorizonteEdit from './components/HorizonteEdit';
import Directivos from './components/Directivos';
import DirectivosCreate from './components/DirectivosCreate';
import DirectivosEdit from './components/DirectivosEdit';
import Administrativos from './components/Administrativos';
import AdministrativosCreate from './components/AdministrativosCreate';
import AdministrativosEdit from './components/AdministrativosEdit';
import Docentes from './components/Docentes';
import DocentesCreate from './components/DocentesCreate';
import DocentesEdit from './components/DocentesEdit';
import RendicionCuentas from './components/RendicionCuentas';
import RendicionCuentasEdit from './components/RendicionCuentasEdit';
import EnfoquePedagogico from './components/EnfoquePedagogico';
import EnfoquePedagogicoEdit from './components/EnfoquePedagogicoEdit';
import ModeloPedagogico from './components/ModeloPedagogico';
import ModeloPedagogicoEdit from './components/ModeloPedagogicoEdit';
import ManualConvivencia from './components/ManualConvivencia';
import ManualConvivenciaEdit from './components/ManualConvivenciaEdit';
import TramiteRetiros from './components/TramiteRetiros';
import TramiteRetirosEdit from './components/TramiteRetirosEdit';
import ProtocoloBioseguridad from './components/ProtocoloBioseguridad';
import ProtocoloBioseguridadEdit from './components/ProtocoloBioseguridadEdit';
import Convocatorias from './components/Convocatorias';
import ConvocatoriasEdit from './components/ConvocatoriasEdit';
import ConvocatoriasCreate from './components/ConvocatoriasCreate';
import ContactForm from './components/ContactForm';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return  <div class="d-flex justify-content-center">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>;
  }

  return (
    <Router>
      <SocialLinks />
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resenahistorica" element={<ResenaHistorica />} />
        <Route path="/horizonteinstitucional" element={<Horizonte />} />
        <Route path="/directivos" element={<Directivos />} />
        <Route path="/administrativos" element={<Administrativos />} />
        <Route path="/docentes" element={<Docentes />} />
        <Route path="/rendicioncuentas" element={<RendicionCuentas />} />
        <Route path="/enfoquepedagogico" element={<EnfoquePedagogico />} />
        <Route path="/modelopedagogico" element={<ModeloPedagogico />} />
        <Route path="/manualconvivencia" element={<ManualConvivencia />} />
        <Route path="/tramiteretiros" element={<TramiteRetiros />} />
        <Route path="/protocolobioseguridad" element={<ProtocoloBioseguridad />} />
        <Route path="/convocatorias" element={<Convocatorias />} />
        <Route path="/contacto" element={<ContactForm />} />
        <Route path="/login" element={<Login />} />
        {user ? (
          <>
            <Route path="/admin" element={<Admin />} />
            <Route path="/carouselEdit/:id" element={<CarouselEdit />} />
            <Route path="/resenahistoricaEdit/:id" element={<ResenaHistoricaEdit />} />
            <Route path="/horizonteEdit/:id" element={<HorizonteEdit />} />
            <Route path="/carouselCreate" element={<CarouselCreate />} />
            <Route path="/directivosEdit/:id" element={<DirectivosEdit />} />
            <Route path="/directivosCreate" element={<DirectivosCreate />} />
            <Route path="/administrativosEdit/:id" element={<AdministrativosEdit />} />
            <Route path="/administrativosCreate" element={<AdministrativosCreate />} />
            <Route path="/docentesEdit/:id" element={<DocentesEdit />} />
            <Route path="/docentesCreate" element={<DocentesCreate />} />
            <Route path="/rendicioncuentasEdit/:id" element={<RendicionCuentasEdit />} />
            <Route path="/enfoquepedagogicoEdit/:id" element={<EnfoquePedagogicoEdit />} />
            <Route path="/modelopedagogicoEdit/:id" element={<ModeloPedagogicoEdit />} />
            <Route path="/manualconvivenciaEdit/:id" element={<ManualConvivenciaEdit />} />
            <Route path="/tramiteretirosEdit/:id" element={<TramiteRetirosEdit />} />
            <Route path="/protocolobioseguridadEdit/:id" element={<ProtocoloBioseguridadEdit />} />
            <Route path="/convocatoriasEdit/:id" element={<ConvocatoriasEdit />} />
            <Route path="/convocatoriasCreate" element={<ConvocatoriasCreate />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
      <Radio />
      <Footer />
      <FloatingButton />
      <Copyright />
    </Router>
  );
};

export default App;
