import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importa axios para hacer solicitudes HTTP
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const ManualConvivencia = () => {
  const [manualconvivenciaData, setManualConvivenciaData] = useState([]);

  useEffect(() => {
    // Función para obtener datos desde el servidor
    const fetchManualConvivenciaData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/manualconvivencia`); // Cambia la ruta según tu configuración de servidor
        setManualConvivenciaData(response.data);
      } catch (error) {
        console.error('Error fetching manual convivencia data:', error);
      }
    };

    fetchManualConvivenciaData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {manualconvivenciaData.map((item) => (
        <div key={item._id} className="">
          <div 
            className='singlebackground' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className="singlebackground-text animated-text ">
            <h2>Manual de convivencia</h2>
            <p>El manual de convivencia puede entenderse como una herramienta en la que se consignan los acuerdos de la comunidad educativa para facilitar y garantizar la armonía en la vida diaria de los establecimientos educativos.</p>
          </div>
          <div className='container'>
            <div className="caption">
              <h2 className='p-5 text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
              <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3>
              <p className='text-justify'>{item.stringone}</p>
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
                <div style={{ height: '750px' }}>
                  <Viewer fileUrl={`/media/${item.pdfone}`} />
                </div>
              </Worker>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ManualConvivencia;
