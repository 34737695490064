import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importa axios para hacer solicitudes HTTP

const ResenaHistorica = () => {
  const [resenaData, setResenaData] = useState([]);

  useEffect(() => {
    // Función para obtener datos del carrusel desde el servidor
    const fetchResenaData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/resenahistorica`); // Cambia la ruta según tu configuración de servidor
        setResenaData(response.data);
      } catch (error) {
        console.error('Error fetching reseña historica data:', error);
      }
    };

    fetchResenaData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {/* Renderiza el contenido de la reseña historica */}
      {resenaData.map((item) => (
        <div key={item._id} className="resena-item">
          <div 
            className='backgroundresena' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className='container'>
            <div className="caption">
              <h2 className='text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
              <p className='text-justify'>{item.stringone}</p>
            </div>
            <div className="p-4 mt-3 bg-white bg-opacity-50">
              <p className='text-justify'>{item.stringtwo}</p>
            </div>
            <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
              <p className='text-justify'>{item.stringthree}</p>
            </div>
            <div className="p-4 mt-3 bg-white bg-opacity-50">
              <p className='text-justify'>{item.stringfour}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResenaHistorica;
