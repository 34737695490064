import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ResenaHistoricaEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    stringone: '',
    stringtwo: '',
    stringthree: '',
    stringfour: '',
    image: '',
  });

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/resenahistorica/${id}`);
        setItemData(response.data);
      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    };

    fetchItemData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/resenahistorica/${id}`, itemData);
      navigate('/admin');
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };
  
  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  return (
    <div className="container">
      <h2 className="text-center">Editar Reseña Histórica</h2>
      <form>
        <div className="mb-3">
          <label className="form-label">Título</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Texto uno</label>
          <textarea
            className="form-control"
            rows="3"
            name="stringone"
            value={itemData.stringone}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Texto dos</label>
          <textarea
            className="form-control"
            rows="3"
            name="stringtwo"
            value={itemData.stringtwo}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Texto tres</label>
          <textarea
            className="form-control"
            rows="3"
            name="stringthree"
            value={itemData.stringthree}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Texto cuatro</label>
          <textarea
            className="form-control"
            rows="3"
            name="stringfour"
            value={itemData.stringfour}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Imágen</label>
          <input
            type="text"
            className="form-control"
            name="image"
            value={itemData.image}
            onChange={handleChange}
          />
        </div>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Guardar cambios
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default ResenaHistoricaEdit;
