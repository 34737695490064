import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importa axios para hacer solicitudes HTTP

const CarouselComponent = () => {
  const [carouselData, setCarouselData] = useState([]);

  useEffect(() => {
    // Función para obtener datos del carrusel desde el servidor
    const fetchCarouselData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/carousel`); // Cambia la ruta según tu configuración de servidor
        setCarouselData(response.data);
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchCarouselData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {/* Renderiza el contenido del carrusel */}
        {carouselData.map((item, index) => (
          <div key={item._id} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="3000">
            <div className="frame">
              <img src={`/media/${item.image}`} alt={item.title} className='d-block w-100'/>
              <div className="carousel-caption d-none d-md-block bg-primary bg-gradient bg-opacity-50 rounded-pill">
                <div className=""><h3 className=''>{item.title}</h3></div>
                <div className="caption"><p>{item.stringone}</p></div>
              </div>  
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselComponent;
