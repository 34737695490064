import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image';

const ProtocoloBioseguridadEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    strig: '',
    titleone: '',
    stringone: '',
    titletwo: '',
    stringtwo: '',
    imageName: '',
  });

  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/protocolobioseguridad/${id}`);
        setItemData(response.data);
      } catch (error) {
        console.error('Error fetching item data:', error);
      }
    };

    fetchItemData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const uploadedFiles = uploadResponse.data.files;
      const updatedItemData = {
        ...itemData,
        image: uploadedFiles.image || itemData.image,
        imageName: uploadedFiles.image ? imageFile.name : itemData.imageName,
      };

      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/protocolobioseguridad/${id}`, updatedItemData);
      navigate('/admin');
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const maxSize = 6 * 1024 * 1024; // 6MB
  
      if (selectedFile.size > maxSize) {
        alert('El archivo excede el límite: 6MB');
        return;
      }
  
      // Validar la resolución de la imagen
      loadImage(
        selectedFile,
        (img) => {
          const { naturalWidth: width, naturalHeight: height } = img;
          const requiredWidth = 1920;
          const requiredHeight = 650;
  
          if (width !== requiredWidth || height !== requiredHeight) {
            alert(`La imagen debe tener una resolución de ${requiredWidth}x${requiredHeight}px`);
            return;
          }
  
          setImageFile(selectedFile);
  
          setItemData((prevData) => ({
            ...prevData,
            imageName: selectedFile.name,
          }));
        },
        { meta: true }
      );
    }
  };
  
  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    onDrop: (files) => handleDrop(files),
  });

  return (
    <div className="container">
      <h2 className="text-center">Editar protocolo de bioseguridad</h2>
      <form>
        <div className="mb-3">
          <label className="form-label fw-bold">Título</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label fw-bold">texto inicial</label>
          <input
            type="text"
            className="form-control"
            name="string"
            value={itemData.string}
            onChange={handleChange}
          />
        </div>
        <div className='mt-5'>
            <div className="mb-3">
            <label className="form-label fw-bold">Título uno</label>
            <input
                type="text"
                className="form-control"
                name="titleone"
                value={itemData.titleone}
                onChange={handleChange}
            />
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">Texto uno</label>
            <textarea
                className="form-control"
                rows="3"
                name="stringone"
                value={itemData.stringone}
                onChange={handleChange}
            ></textarea>
            </div>
        </div>
        <div className='mt-5'>
            <div className="mb-3">
            <label className="form-label fw-bold">Título dos</label>
            <input
                type="text"
                className="form-control"
                name="titletwo"
                value={itemData.titletwo}
                onChange={handleChange}
            />
            </div>
            <div className="mb-3">
            <label className="form-label fw-bold">Texto dos</label>
            <textarea
                className="form-control"
                rows="3"
                name="stringtwo"
                value={itemData.stringtwo}
                onChange={handleChange}
            ></textarea>
            </div>
        </div>
        <div className="mb-3">
          <label className="form-label fw-bold">imagen</label>
          <div {...getRootPropsImage()} className="dropzone">
              <input {...getInputPropsImage()} />
              {itemData.imageName ? (
              <p>{itemData.imageName}</p>
              ) : (
              <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
          </div>
        </div>
        
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Guardar cambios
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default ProtocoloBioseguridadEdit;
