import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TramiteRetiros = () => {
  const [tramiteretirosData, setTramiteRetirosData] = useState([]);

  useEffect(() => {
    // Función para obtener datos desde el servidor
    const fetchTramiteRetirosData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tramiteretiros`); // Cambia la ruta según tu configuración de servidor
        setTramiteRetirosData(response.data);
      } catch (error) {
        console.error('Error fetching tramites de retiros data:', error);
      }
    };

    fetchTramiteRetirosData(); // Llama a la función para obtener los datos cuando el componente se monte
  }, []);

  return (
    <div className="">
      {tramiteretirosData.map((item) => (
        <div key={item._id} className="">
          <div 
            className='singlebackground' 
            style={{
              backgroundImage: `url(/media/${item.image})`,          
            }}
          ></div>
          <div className="singlebackground-text animated-text ">
            <h2>Trámite de retiros</h2>
            <p>Los retiros seran establecidos en el manual de convivencia</p>
          </div>
          <div className='container'>
            <div className="caption">
              <h2 className='p-5 text-primary text-center fw-bold'>{item.title}</h2>
            </div>
            <div className="p-5 mt-3 bg-gradient bg-opacity-50">
              {/* <h3 className='text-primary text-center fw-bold'>{item.titleone}</h3> */}
              <p className='text-justify'>{item.string}</p>
            </div>
            <div className='row p-5'>
                <div className='col-sm-4'>
                    <div className="card border-warning mb-3">
                        <h4 className="card-header">{item.titleone}</h4>
                        <div className="card-body">
                            <p className="card-text">{item.stringone}</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="card border-warning mb-3">
                        <h4 className="card-header">{item.titletwo}</h4>
                        <div className="card-body">
                            <p className="card-text">{item.stringtwo}</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="card border-warning mb-3">
                        <h4 className="card-header">{item.titlethree}</h4>
                        <div className="card-body">
                            <p className="card-text">{item.stringthree}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row p-5'>
                <div className='col-sm-4'>
                    <div className="card border-warning mb-3">
                        <h4 className="card-header">{item.titlefour}</h4>
                        <div className="card-body">
                            <p className="card-text">{item.stringfour}</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className="card border-warning mb-3">
                        <h4 className="card-header">{item.titlefive}</h4>
                        <div className="card-body">
                            <p className="card-text">{item.stringfive}</p>
                        </div>
                    </div>
                </div>
            </div>     
          </div>
        </div>
      ))}
    </div>
  );
};

export default TramiteRetiros;
