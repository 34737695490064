import React from 'react';

const HomePedagogico = () => {
  return (
    <div id="r_HomePedagogico">
      <div className=" text-center">
        <div className="row">
            <div className="col-12 col-sm-6 p-5">
                <img src={'/media/homepedagogico.png'} className="rounded border border-success border-5 mb-2"  alt="Estudiantes I.E. La Esperanza"></img>
            </div>
            <div className="col-12 col-sm-6 p-5">
                <h1 className='text-primary fw-bold'>¿QUIÉN DIJO QUE LA EDUCACIÓN ERA ABURRIDA?</h1>
                <h3 className='text-primary fw-bold'>Jugamos y aprendemos</h3>
                <div className="container text-center">
                    <div className="row align-items-start">
                        <div className="col-6 col-sm-3 text-bg-info p-3">
                            <img src={'/media/libroabierto.png'} className="text-bg-info"  height={'150'} alt="Libro abierto I.E. La Esperanza"></img>
                            <h5 className='text-white fw-bold pt-3'>Aprendiendo</h5>
                        </div>
                        <div className="col-6 col-sm-3 text-bg-warning p-3">
                            <img src={'/media/bombilla.png'} className="text-bg-warning"  height={'150'} alt="Libro abierto I.E. La Esperanza"></img>
                            <h5 className='text-white fw-bold pt-3'>Filosofía</h5>
                        </div>
                        <div className="col-6 col-sm-3 bg-danger-subtle p-3">
                            <img src={'/media/mano.png'} className="bg-danger-subtle"  height={'150'} alt="Libro abierto I.E. La Esperanza"></img>
                            <h5 className='text-white fw-bold pt-3'>Práctica</h5>
                        </div>
                        <div className="col-6 col-sm-3 bg-success p-3">
                            <img src={'/media/rompecabezas.png'} className="bg-success"  height={'150'} alt="Libro abierto I.E. La Esperanza"></img>
                            <h5 className='text-white fw-bold pt-3'>Juegos</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  );
};

export default HomePedagogico;