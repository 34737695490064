import React from 'react';

const Services = () => {
  return (
    <div id="r_Services">
      <div className='row text-center p-5 mt-5'>
        <h1 className='text-primary fw-bold'>SERVICIOS</h1>
        <h3 className='text-primary fw-bold'>Ofertamos servicios educativos en los niveles de educación formal</h3>
        <div className='col-12 col-sm-3 service-item'>
          <div className="image-container">
            <img src={'/media/preescolar_ieesperanza.jpg'} className="service-image" alt="Preescolar I.E. La Esperanza"></img>
          </div>
          <h5 className='fw-bold pt-3'>Preescolar</h5>
        </div>
        <div className='col-12 col-sm-3 service-item'>
          <div className="image-container">
            <img src={'/media/primaria_ieesperanza.jpg'} className="service-image" alt="Primaria I.E. La Esperanza"></img>
          </div>
          <h5 className='fw-bold pt-3'>Primaria</h5>
        </div>
        <div className='col-12 col-sm-3 service-item'>
          <div className="image-container">
            <img src={'/media/secundaria_ieesperanza.jpg'} className="service-image" alt="Secundaria I.E. La Esperanza"></img>
          </div>
          <h5 className='fw-bold pt-3'>Secundaria</h5>
        </div>
        <div className='col-12 col-sm-3 service-item'>
          <div className="image-container">
            <img src={'/media/sabatinos_ieesperanza.jpg'} className="service-image" alt="Sabatinos I.E. La Esperanza"></img>
          </div>
          <h5 className='fw-bold pt-3'>Sabatinos</h5>
        </div>
        <h4 className=''>Preescolar-Transición, Educación Básica Primaria, Educación Básica Secundaria, Educación Media Académica y Jornada fin de semana, Educación para jóvenes y adultos.</h4>
      </div>
    </div>
  );
};

export default Services;
