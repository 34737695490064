import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="sticky-top navbar fixed-top navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={`/media/logo_ieesperanza.png`} alt="Logo" width="80" height="80" className="d-inline-block align-text-top" />
            </Link>
            <Link className="navbar-brand acolor" to="/">I.E. La Esperanza</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="justify-content-center collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link acolor" to="/">Inicio</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link acolor dropdown-toggle"  role="button" data-bs-toggle="dropdown" to="/other">Nuestra Institución</Link>
                        <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/resenahistorica">Reseña historica</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/horizonteinstitucional">Horizonte institucional</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/directivos">Directivos</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/administrativos">Administrativos</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/docentes">Docentes</Link>
                          </li> 
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link acolor dropdown-toggle"  role="button" data-bs-toggle="dropdown" to="/other">Gestión Académica</Link>
                      <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/enfoquepedagogico">Enfoque pedagógico</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/modelopedagogico">Modelo pedagógico</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/other">Gobierno estudiantil</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/manualconvivencia">Manual de convivencia</Link>
                          </li>
                          <li>
                            {/* <Link className="dropdown-item nav-link acolor" to="/other">Boletín académico</Link> */}
                            <a className="dropdown-item nav-link acolor" href='http://ielaesperanza.ssm.com.co/View/Boletin/BoletinXDocumento.aspx' target="_blank" rel="noopener noreferrer">Boletín académico</a>
                          </li> 
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/other">Aula extendida</Link>
                          </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link acolor dropdown-toggle"  role="button" data-bs-toggle="dropdown" to="/other">Gestión Administrativa</Link>
                      <ul className="dropdown-menu">
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/other">Ejecuciones presupuestales</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/rendicioncuentas">Rendición de cuentas</Link>
                          </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="nav-link acolor dropdown-toggle"  role="button" data-bs-toggle="dropdown" to="/other">Servicios</Link>
                        <ul className="dropdown-menu">
                          <li>
                            {/* <Link className="dropdown-item nav-link acolor" to="/other">Trámites de matricula</Link> */}                            
                            <a className="dropdown-item nav-link acolor" href='http://ielaesperanza.ssm.com.co/view/publico/prematricula.aspx' target="_blank" rel="noopener noreferrer">Trámites de matricula</a>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/tramiteretiros">Trámites de retiros</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/protocolobioseguridad">Protocolo de bioseguridad</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/convocatorias">Contratación</Link>
                          </li>
                          <li>
                            <Link className="dropdown-item nav-link acolor" to="/login">Administrar</Link>
                          </li>
                        </ul>
                    </li>
                    <li className="nav-item"><Link className="nav-link acolor" to="/contacto">Contacto</Link></li>
                </ul>
            </div>
        </div>    
    </nav>
  );
};

export default Navigation;