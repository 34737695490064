import React from 'react';

const Header = () => {
  return (
    <div id="r_header">
      {/* Contenido del encabezado */}
    </div>
  );
};

export default Header;
