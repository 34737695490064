import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import loadImage from 'blueimp-load-image';

const ConvocatoriasCreate = () => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    title: '',
    string: '',
    titleone: '',
    stringone: '',
    pdfone: '',
    titletwo: '',
    stringtwo: '',
    pdftwo: '',
    titlethree: '',
    stringthree: '',
    pdfthree: '',
    titlefour: '',
    stringfour: '',
    pdffour: '',
    image: '',
  });
  const [files, setFiles] = useState({
    pdfone: null,
    pdftwo: null,
    pdfthree: null,
    pdffour: null,
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreate = async () => {
    try {
      const formData = new FormData();
      formData.append('image', files.image);
      formData.append('title', itemData.title);
      formData.append('string', itemData.string);
      formData.append('titleone', itemData.titleone);
      formData.append('stringone', itemData.stringone);
      formData.append('pdfone', files.pdfone);
      formData.append('titletwo', itemData.titletwo);
      formData.append('stringtwo', itemData.stringtwo);
      formData.append('pdftwo', files.pdftwo);
      formData.append('titlethree', itemData.titlethree);
      formData.append('stringthree', itemData.stringthree);
      formData.append('pdfthree', files.pdfthree);
      formData.append('titlefour', itemData.titlefour);
      formData.append('stringfour', itemData.stringfour);
      formData.append('pdffour', files.pdffour);

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/convocatorias`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/admin');
    } catch (error) {
      console.error('Error creating new item:', error);
    }
  };

  const handleBackToAdmin = () => {
    navigate('/admin');
  };

  const handleDrop = (acceptedFiles, fieldName) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      const maxSize = 6 * 1024 * 1024; // 6MB

      if (selectedFile.size > maxSize) {
        alert('El archivo excede el límite: 6MB');
        return;
      }

      if (fieldName === 'image') {
        // Validar la resolución de la imagen
        loadImage(
          selectedFile,
          (img) => {
            const { naturalWidth: width, naturalHeight: height } = img;
            const requiredWidth = 1;
            const requiredHeight = 1;

            if (width !== requiredWidth || height !== requiredHeight) {
              alert(`La imagen debe tener una resolución de ${requiredWidth}x${requiredHeight} px`);
              return;
            }

            setFiles((prevFiles) => ({
              ...prevFiles,
              [fieldName]: selectedFile,
            }));

            setItemData((prevData) => ({
              ...prevData,
              [`${fieldName}Name`]: selectedFile.name,
            }));
          },
          { meta: true }
        );
      } else {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [fieldName]: selectedFile,
        }));

        setItemData((prevData) => ({
          ...prevData,
          [`${fieldName}Name`]: selectedFile.name,
        }));
      }
    }
  };

  const { getRootProps: getRootPropsPdfOne, getInputProps: getInputPropsPdfOne } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdfone'),
  });
  const { getRootProps: getRootPropsPdfTwo, getInputProps: getInputPropsPdfTwo } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdftwo'),
  });
  const { getRootProps: getRootPropsPdfThree, getInputProps: getInputPropsPdfThree } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdfthree'),
  });
  const { getRootProps: getRootPropsPdfFour, getInputProps: getInputPropsPdfFour } = useDropzone({
    onDrop: (files) => handleDrop(files, 'pdffour'),
  });
  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } = useDropzone({
    onDrop: (files) => handleDrop(files, 'image'),
  });

  return (
    <div className="container">
      <h2 className="text-center">Crear nueva convocatoria</h2>
      <form>
        <div className="mb-3">
          <label className="form-label fw-bold">Título</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={itemData.title}
            onChange={handleChange}
          />
        </div>
        <div className="mt-5">
          <div className="mb-3">
            <label className="form-label fw-bold">Título uno</label>
            <input
              type="text"
              className="form-control"
              name="titleone"
              value={itemData.titleone}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto uno</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringone"
              value={itemData.stringone}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">PDF uno</label>
            <div {...getRootPropsPdfOne()} className="dropzone">
              <input {...getInputPropsPdfOne()} />
              {itemData.pdfoneName ? (
                <p>{itemData.pdfoneName}</p>
              ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="mb-3">
            <label className="form-label fw-bold">Título dos</label>
            <input
              type="text"
              className="form-control"
              name="titletwo"
              value={itemData.titletwo}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto dos</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringtwo"
              value={itemData.stringtwo}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">PDF dos</label>
            <div {...getRootPropsPdfTwo()} className="dropzone">
              <input {...getInputPropsPdfTwo()} />
              {itemData.pdftwoName ? (
                <p>{itemData.pdftwoName}</p>
              ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="mb-3">
            <label className="form-label fw-bold">Título tres</label>
            <input
              type="text"
              className="form-control"
              name="titlethree"
              value={itemData.titlethree}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto tres</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringthree"
              value={itemData.stringthree}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">PDF tres</label>
            <div {...getRootPropsPdfThree()} className="dropzone">
              <input {...getInputPropsPdfThree()} />
              {itemData.pdfthreeName ? (
                <p>{itemData.pdfthreeName}</p>
              ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="mb-3">
            <label className="form-label fw-bold">Título cuatro</label>
            <input
              type="text"
              className="form-control"
              name="titlefour"
              value={itemData.titlefour}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Texto cuatro</label>
            <textarea
              className="form-control"
              rows="3"
              name="stringfour"
              value={itemData.stringfour}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">PDF cuatro</label>
            <div {...getRootPropsPdfFour()} className="dropzone">
              <input {...getInputPropsPdfFour()} />
              {itemData.pdffourName ? (
                <p>{itemData.pdffourName}</p>
              ) : (
                <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label fw-bold">Imagen</label>
          <div {...getRootPropsImage()} className="dropzone">
            <input {...getInputPropsImage()} />
            {itemData.imageName ? (
              <p>{itemData.imageName}</p>
            ) : (
              <p>Arrastra y suelta un archivo aquí, o haz clic para seleccionar un archivo</p>
            )}
          </div>
        </div>
        <button type="button" className="btn btn-primary" onClick={handleCreate}>
          Crear
        </button>
        <button type="button" className="btn btn-success" onClick={handleBackToAdmin}>
          Volver a administración
        </button>
      </form>
    </div>
  );
};

export default ConvocatoriasCreate;
