import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Carousel from './Carousel';

const Convocatorias = () => {
  const [convocatoriasData, setConvocatoriasData] = useState([]);

  useEffect(() => {
    const fetchConvocatoriasData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/convocatorias`);
        setConvocatoriasData(response.data);
      } catch (error) {
        console.error('Error fetching convocatorias data:', error);
      }
    };

    fetchConvocatoriasData();
  }, []);

  return (
    <div>
      <Carousel />
      <div className="container">
        <h2 className='text-primary text-center fw-bold p-5'>CONTRATOS Y CONVOCATORIAS VIGENTES</h2>
        <div className="row">
          <div className="accordion" id="accordionExample">
            {/* Renderiza el contenido de las convocatorias */}
            {convocatoriasData.map((item) => (
              <div className="accordion-item" key={item._id}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${item._id}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${item._id}`}
                  >
                    <h5 className="text-center fw-bold">{item.title}</h5>
                  </button>
                </h2>
                <div
                  id={`collapse-${item._id}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div>
                      <p className="text-justify">{item.string}</p>
                    </div>                         
                    {item.titleone && (
                      <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
                        <h5 className='text-center fw-bold'>{item.titleone}</h5>
                        <p className='text-justify'>{item.stringone}</p>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
                          <div style={{ height: '450px' }}>
                            <Viewer fileUrl={`/media/${item.pdfone}`} />
                          </div>
                        </Worker>
                      </div>
                    )}
                    {item.titletwo && (
                      <div className="p-5 mt-3 bg-gradient bg-opacity-50">
                        <h5 className='text-center fw-bold'>{item.titletwo}</h5>
                        <p className='text-justify'>{item.stringtwo}</p>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
                          <div style={{ height: '450px' }}>
                            <Viewer fileUrl={`/media/${item.pdftwo}`} />
                          </div>
                        </Worker>
                      </div>
                    )}
                    {item.titlethree && (
                      <div className="p-5 mt-3 bg-primary bg-gradient bg-opacity-50">
                        <h5 className='text-center fw-bold'>{item.titlethree}</h5>
                        <p className='text-justify'>{item.stringthree}</p>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
                          <div style={{ height: '450px' }}>
                            <Viewer fileUrl={`/media/${item.pdfthree}`} />
                          </div>
                        </Worker>
                      </div>
                    )}
                    {item.titlefour && (
                      <div className="p-5 mt-3 bg-gradient bg-opacity-50">
                        <h5 className='text-center fw-bold'>{item.titlefour}</h5>
                        <p className='text-justify'>{item.stringfour}</p>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js`}>
                          <div style={{ height: '450px' }}>
                            <Viewer fileUrl={`/media/${item.pdffour}`} />
                          </div>
                        </Worker>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Convocatorias;
